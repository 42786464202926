<template>
  <div class="with-anchors">
    <h1 class="display-l">Logo</h1>

    <!-- Logo suite -->
    <section>
        <h2 id="suite" class="display-m">Logo suite</h2>
        <p class="section-heading">Masterbrand</p>
        <p>Our logo is available in a horizontal and vertical format, the horizontal version is always preferred. However, where horizontal space is at a premium the vertical version should be used to ensure the logo appears at an appropriate scale.</p>
        <p><img src="../../assets/img/logo1.png" alt="" role="presentation" width="650"/></p><br/>

        <p class="section-heading">Symbol</p>
        <p>The symbol should be applied as a signature of the complete brand mark when in the context of our communication channels e.g. a Facebook display picture.
Please note our logo suite is available in either white or navy and should always adheres to best practice accessibility rules, to ensure it is legible and has maximum standout.</p>
        <p><img src="../../assets/img/logo2.png" alt="" role="presentation" width="450"/></p><br/>


        <p class="section-heading">Product Brands</p>
        <p>To future proof the Intelematics' brand family the following rules should be followed to maintain consistency when creating a product brand.
The product name should:
        <ul>
            <li>Always appear in 'Title Case'</li>
            <li>Set in the brand font 'Graphik Regular'</li>
            <li>Aligned horizontally with the symbol on the left, as seen in the examples</li>
        </ul>
        </p>
        <p><img src="../../assets/img/logo3.png" alt="" role="presentation" width="600"/></p><br/>
    </section>

    <!-- clear space -->
    <section id="clear">
        <h2 id="clear" class="display-m">Clear space and minimum size</h2>
        <p class="section-heading">Clear space</p>
        <p>Our logo is available in a horizontal and vertical format, the horizontal version is always preferred. However, where horizontal space is at a premium the vertical version should be used to ensure the logo appears at an appropriate scale.</p>
        <p><img src="../../assets/img/logo4.png" alt="" role="presentation"  width="800"/></p><br/>

        <p class="section-heading">Minimum size</p>
        <p>For optimal reproduction, we recommend using our logos no smaller than the specified minimum sizes. The primary horizontal and vertical versions should never appear smaller than the widths listed below.</p>
        <ul>
            <li>Horizontal: 25mm or 110px</li>
            <li>Vertical 18mm or 80px</li>
        </ul>
        <p><img src="../../assets/img/logo5.png" alt="" role="presentation" /></p><br/>
    </section>

    <DownloadCTA title="Download logo suite" 
                description="Files available in .SVG and .PNG formats" 
                buttonTitle="Download .zip"
                link="/zip/Intelematics-logos.zip" />

    <AnchorMenu :anchors="logoMenu"/>

  </div>
</template>

<script>
import DownloadCTA from "../common/DownloadCTA"
import AnchorMenu from "../common/AnchorMenu";
import { logoAnchors } from '../../assets/data/anchors'

export default {
  name: 'Logo',
  components: {
    DownloadCTA,
    AnchorMenu
  },
  data: () => {
    return {
      logoMenu: logoAnchors,
    };
  },
}
</script>
